<template>
	<div>
		<CRow class="mb-3">
			<CCol class="text-right">
				<div
					class="btn btn-secondary"
					@click="$refs['modal-sync-branch'].open()"
				>
					<CIcon class="mr-2" name="cil-plus" /> Sync warehouse/branch
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="d-flex align-items-start">
				<form
					class="flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by Warehouse/branch name, Com7 branch ID"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					class="ml-3"
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="filter-box rounded-sm pt-3 px-3 mb-3"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.is_active, STATUS_OPTIONS)"
									:searchable="false"
									:options="STATUS_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Warehouse/branch status"
									@input="handleInputStatus"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.province_id, PROVINCE_OPTIONS)"
									:options="PROVINCE_OPTIONS"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Province"
									@input="handleInputProvince"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									:value="getSelectedValue(queryParams.branch_brand_type_id, warehouseTypeOptions)"
									:options="warehouseTypeOptions"
									:allow-empty="false"
									class="select-custom"
									label="name"
									track-by="value"
									label-drop-down="Brand type"
									@input="handleInputBrandType"
								/>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow class="mb-4">
			<CCol>
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="Warehouse/branch(s) found"
					@onReset="resetFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage
					}"
					:fields="WAREHOUSE_TABLE_FIELDS"
					:items="warehouseTableData"
					clickable-rows
					link-to="WarehouseEdit"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "warehouses" }) }}
							</p>
						</div>
					</template>
					<template #logo="{item}">
						<img class="thumbnail-54" :src="item">
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="item.value">
							{{ item.name }}
						</CBadge>
					</template>
				</BaseTable>
			</CCol>
		</CRow>

		<ModalIdList
			ref="modal-sync-branch"
			title="Sync branches from BNN"
			input-placeholder="Paste or type itec branch ID number here"
			list-title="ITEC Branch ID"
			no-data-title="No Sync Branch yet"
			no-data-description="Add branch number in order to set condition."
			@onConfirm="handleSyncBranch"
		/>

		<BaseModalLoading
			ref="modal-loading"
			modal-title="Sync branches"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ModalIdList from '@/components/ModalIdList.vue';
import { cleanObject, cloneDeep, randomSearchString, hasSearchFilter } from '../assets/js/helpers';
import { STATUS_OPTIONS } from '../enums/warehouses';

const WAREHOUSE_TABLE_FIELDS = [
	{ key: 'logo', label: '', class: 'col-logo', cellClass: 'cell-logo' },
	{ key: 'com7BranchId', label: 'Com7 branch ID', class: 'col-com7-branch-id' },
	{ key: 'brandTypeName', label: 'Brand Type', class: 'col-brand-type' },
	{ key: 'name', label: 'Name', class: 'col-name th-mw-300' },
	{ key: 'status', label: 'Status', class: 'col-status' },
	{ key: 'address', label: 'Address', class: 'col-address th-mw-370' },
	{ key: 'province', label: 'Province', class: 'col-province', cellClass: 'td-nowrap' },
	{ key: 'postcode', label: 'Postcode', class: 'col-postcode' },
	{ key: 'businessHours', label: 'Working days', class: 'col-business-hours', cellClass: 'td-nowrap' },
	{ key: 'email', label: 'Email', class: 'col-email', cellClass: 'td-nowrap' },
	{ key: 'phone', label: 'Phone number', class: 'col-phone' },
];

export default {
	name: 'WarehouseList',
	components: {
		ModalIdList,
	},
	data() {
		return {
			WAREHOUSE_TABLE_FIELDS,
			STATUS_OPTIONS,
			isLoading: false,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				per_page: Number(this.$route.query.per_page) || null,
				is_active: (Number(this.$route.query.is_active) === 0 || Number(this.$route.query.is_active) === 1)
					? Number(this.$route.query.is_active)
					: null,
				province_id: Number(this.$route.query.province_id) || null,
				branch_brand_type_id: Number(this.$route.query.branch_brand_type_id) || null,
				r: randomSearchString(),
			},
			isShowFilter: false,
		};
	},
	computed: {
		...mapState('warehouses', {
			list: 'list',
		}),
		...mapGetters({
			warehouseTableData: 'warehouses/warehouseTableData',
			warehouseTypes: 'warehouses/warehouseTypes',
			PROVINCE_OPTIONS: 'addresses/getProvinceOptions',
		}),
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		warehouseTypeOptions() {
			const allOption = { name: 'All brand types', value: null };
			const types = this.warehouseTypes.map((type) => ({
				name: type.name,
				value: type.id,
			}));

			return [allOption, ...types];
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		this.isLoading = true;
		await Promise.all([
			this.fetchProvinces(),
			this.getWarehouseList(this.queryParams),
			this.getWarehouseTypes({ per_page: 'all' }),
		]);
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getWarehouseList: 'warehouses/getWarehouseList',
			getWarehouseTypes: 'warehouses/getWarehouseTypes',
			fetchProvinces: 'addresses/fetchProvinces',
			postSyncBranch: 'syncBranch/postSyncBranch',
		}),
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handleInputStatus(v) {
			this.queryParams.page = null;
			this.queryParams.is_active = v.value;
			this.updateUrlParams();
		},
		handleInputProvince(v) {
			this.queryParams.page = null;
			this.queryParams.province_id = v.value;
			this.updateUrlParams();
		},
		handleInputBrandType(v) {
			this.queryParams.page = null;
			this.queryParams.branch_brand_type_id = v.value;
			this.updateUrlParams();
		},
		getSelectedValue(selectedValue = null, options = []) {
			const statusOptionValues = Object.values(options);
			const findStatus = ({ value }) => value === selectedValue;
			return statusOptionValues.find(findStatus) || { name: null, value: null };
		},
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		async handleSyncBranch(branchs = []) {
			try {
				this.$refs['modal-loading'].open();
				await this.postSyncBranch({
					itec_ids: [...branchs],
				});

				this.isLoading = true;
				await Promise.all([
					this.getWarehouseList(this.queryParams),
					this.getWarehouseTypes({ per_page: 'all' }),
				]);
				this.isLoading = false;
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
		resetFilter() {
			this.queryParams = {
				q: null,
				page: null,
				per_page: null,
				is_active: null,
				province_id: null,
			};
			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	.filter-box {
		background-color: $color-gray-100;
	}
</style>
